.box-update-profile {
    .form-profile {
        .item {
            margin-bottom: 10px;
            .title {
                margin: 0 0 6px;
                font-weight: 900;
                font-size: 14px;
            }
            .ant-form-item {
                margin-bottom: 0;
                .ant-input {
                    border-radius: unset;
                }
                &.birthday-custom {
                    margin-bottom: 12px;
                    .ant-form-item-explain-error {
                        font-size: 12px;
                        font-weight: 500;
                        position: absolute;
                        top: calc(100% - 12px);
                        color: var(--rc-red-v3);
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .box-btn {
            &.ant-btn  {
                background: var(--rc-green);
                height: 50px;
                border: unset;
                border-radius: unset;
                color: var(--rc-white);
                font-size: 14px;
                font-weight: 900;
                margin-top: 29px;
            }
        }
    }
}
