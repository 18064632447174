.purchase {
    padding: 20px 12px;

    .title-head {
        font-weight: 900;
        font-size: 16px;
        text-align: center;
        color: var(--rc-gray-v2);
        margin: 21px 0 40px;
        white-space: pre-line;
        .ant-skeleton {
            .ant-skeleton-content {
                vertical-align: middle;
                .ant-skeleton-title {
                    margin: 0 auto;
                }
                .ant-skeleton-paragraph {
                    li {
                        margin: 0 auto;
                        margin-top: 16px;
                        width: 80% !important;
                    }
                }
            }
        }
    }

    .list {
        padding-left: 13px;
        padding-right: 13px;
        .item {
            display: flex;
            align-items: flex-start;
            position: relative;
            margin-bottom: 20px;
            height: 64px;
            &:nth-child(2) {
                margin-bottom: 23px;
            }
            &:last-child {
                margin-bottom: 13px;
            }

            &::after {
                content: '';
                width: calc(100% - 50px - 15px);
                height: 1px;
                background-color: var(--rc-header);
                position: absolute;
                bottom: 10px;
                right: 0;
            }

            .purchase-dropdown {
                border-left: 1px solid var(--rc-header);
                border-right: 1px solid var(--rc-header);
                position: absolute;
                top: 84%;
                right: 0;
                background-color: var(--rc-white);
                max-height: 0em;
                overflow: hidden;
                transition: max-height 0.5s ease;
                width: calc(100% - 50px - 17px);
                z-index: 99;
                .purchase-dropdown-list {
                    .purchase-dropdown-item {
                        padding: 9px 20px;
                        &:first-child {
                            padding: 7px 23px;
                        }
                        .up {
                            margin: 0;
                            color: var(--rc-orange);
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 16px;
                        }
                        .down {
                            span {
                                &:first-child {
                                    font-family: 'Century' !important;
                                    letter-spacing: -0.5px;
                                    display: inline-block;
                                    margin-right: 4px;
                                    font-weight: 700;
                                    line-height: 2;
                                }
                                &:nth-child(3) {
                                    span {
                                        font-size: 12px;
                                        &:nth-child(1) {
                                            font-size: 20px;
                                            font-family: 'Noto Sans JP', sans-serif !important;
                                        }
                                        &:nth-child(2) {
                                            letter-spacing: -0.5px;
                                            font-size: 20px;
                                            font-family: 'Century' !important;
                                        }
                                        &:nth-child(3) {
                                            font-size: 12px;
                                        }
                                        &:nth-child(4) {
                                            font-size: 20px;
                                        }
                                    }
                                }
                                &:last-child {
                                    font-family: 'Noto Sans JP', sans-serif !important;
                                    margin-left: auto;
                                }
                            }
                            .stone {
                                font-family: 'Noto Sans JP', sans-serif !important;
                                font-size: 13px;
                                font-weight: 900;
                                padding-top: 3px;
                                letter-spacing: 0.09em;
                            }
                            display: flex;
                            align-items: center;
                            font-size: 20px;
                            color: var(--rc-gray-v2);
                            font-weight: 700;
                        }
                        &:hover {
                            background-color: var(--rc-gray-v3);
                        }
                        &.active {
                            background-color: var(--rc-gray-v3);
                        }
                        &:last-child {
                            font-size: 16px;
                            color: var(--rc-main);
                            letter-spacing: 0.04em;
                            font-weight: 700;
                            padding: 9px 20px 28px 20px;
                        }
                    }
                }
                &.active {
                    overflow: auto;
                    max-height: 380px;
                    width: calc(100% - 50px - 17px);
                    border: 1px solid var(--rc-header);
                    border-top: none;
                    z-index: 9999;
                }
            }

            .left {
                width: 50px;
                .img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
                .img2 {
                    padding-top: 8px;
                    width: 50px;
                }
            }
            .right {
                width: calc(100% - 56px - 17px);
                margin-left: 17px;
                color: var(--rc-gray-v2);
                .title {
                    font-size: 13px;
                    font-weight: 500;
                    margin: 0 0 8px;
                    line-height: 1;
                }
                .desc {
                    font-size: 12px;
                    font-weight: 900;
                    margin: 0;
                    line-height: 1;
                    letter-spacing: 0.09em;
                    text-transform: lowercase;
                    span {
                        letter-spacing: 0;
                        font-weight: 700;
                        font-family: 'Century' !important;
                        font-size: 20px;
                        letter-spacing: -0.5px;
                        &:first-child {
                            font-size: 24px;
                        }
                    }
                    .desc-stone {
                        font-family: 'Noto Sans JP', sans-serif !important;
                        font-weight: 900;
                        font-size: 14px;
                    }
                }
                .desc-card {
                    font-weight: 700 !important;
                }
                .infor-card {
                    text-transform: uppercase;
                }
            }
            &.full {
                &::after {
                    width: 100%;
                }
                .right {
                    width: 100%;
                    margin-left: 0;
                    color: var(--rc-gray-v2);
                    .title {
                        margin: 0 0 8px;
                    }
                    .desc {
                        font-size: 14px;
                        font-weight: 900;
                        color: var(--rc-red);
                    }
                }
            }
            .icon-down {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                .anticon {
                    svg {
                        color: var(--rc-header);
                        font-weight: bold;
                    }
                }
            }
        }
        &.skeleton {
            margin-bottom: 28px;
        }
    }

    .box-amount {
        padding-right: 11px;
        text-align: right;
        .text {
            font-weight: 500;
            margin: 0;
            font-size: 11px;
            color: var(--rc-main);
            line-height: 1;
            letter-spacing: 0.09em;
        }
        .amount {
            font-size: 32px;
            color: var(--rc-black);
            font-weight: 700;
            margin: 0;
            line-height: 1;
            span {
                &:first-child {
                    letter-spacing: -0.5px;
                    font-family: 'Century' !important;
                }
                &:last-child {
                    font-size: 16px;
                    font-weight: 900;
                    letter-spacing: 0.09em;
                }
            }
        }
        .amount2 {
            margin: 2px 0;
            font-weight: bold;
            font-size: 15px;
            color: var(--rc-main);
            .font-number {
                letter-spacing: -0.5px;
                font-family: 'Century' !important;
            }
        }

        &.skeleton {
            .text {
                &:nth-child(2) {
                    .ant-skeleton {
                        .ant-skeleton-content {
                            .ant-skeleton-title {
                                width: 45% !important;
                            }
                        }
                    }
                }
                .ant-skeleton {
                    .ant-skeleton-content {
                        vertical-align: middle;
                        .ant-skeleton-title {
                            margin: 0;
                            margin-left: auto;
                        }
                        .ant-skeleton-paragraph {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .term-of-service {
        font-weight: 500;
        text-align: center;
        margin: 0 0 18px;
        font-size: 13px;
        color: var(--rc-main);
        text-decoration: underline;
        .ant-skeleton {
            .ant-skeleton-content {
                vertical-align: middle;
                .ant-skeleton-title {
                    margin: 0 auto;
                }
            }
        }
    }

    .box-btn {
        .ant-space {
            display: flex;
            margin: auto;
            width: calc(100vw - 50px);
            gap: 15px !important;
            .ant-space-item {
                &:first-child {
                    color: var(--rc-white);
                    .ant-btn {
                        background-color: var(--rc-green);
                        &:disabled {
                            background-color: var(--rc-header);
                            opacity: 1;
                            color: var(--rc-white);
                        }
                    }
                }
                .cancel{
                    span {
                        color: var(--rc-main);
                    }
                }
            }
        }
        .ant-btn {
            // width: 100%;
            font-size: 15px;
            font-weight: 900;
            height: 50px;
            border: unset;
            box-shadow: unset;
        }
    }
}

.popup-charge-point {
    .title {
        letter-spacing: 0.09em;
        font-size: 18px;
        color: var(--rc-main);
        font-weight: 700;
        margin: 0;
    }
    .desc {
        font-size: 13px;
        color: var(--rc-black);
        margin: 0;
        font-weight: 900;
    }
    .box-point {
        padding-top: 21px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .ant-input-affix-wrapper {
            width: 150px;
            border-radius: 2px;
            border: 0.5px solid var(--rc-gray-v5);
            background: var(--rc-white-v2);
            height: 44px;
            padding: 0 4px;
            .ant-input {
                font-family: 'Century' !important;
                letter-spacing: -0.5px;
                font-size: 28px;
                font-weight: 700;
                color: var(--rc-gray-v4);
                text-align: right;
            }
            .ant-input-suffix {
                font-weight: 700;
                font-family: 'Century' !important;
                letter-spacing: -0.5px;
                font-size: 28px;
                color: var(--rc-black);
                margin-inline-start: 0px;
                vertical-align: baseline;
            }
        }
        .stone {
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 0.09em;
            color: var(--rc-gray-v2);
            padding-left: 6px;
            padding-top: 8px;
        }
    }
    .ant-form-item-explain-error {
        text-align: right;
    }
}

.popup-policy {
    .content {
        font-weight: 500;
        white-space: pre-line;
        max-height: 60vh;
        overflow: auto;
    }
}

.form-stripe {
    .box-stripe {
        padding: 0 23px;
        .card-list {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 24px;
            .item {
                height: 20px;
                opacity: 0.5;
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    opacity: 1;
                }
                img {
                    height: 100%;
                }
            }
        }
        .card-element {
            .ant-row {
                row-gap: 14px !important;
            }
            .box-element {
                .title {
                    font-weight: bold;
                    font-size: 12px;
                    color: var(--rc-black);
                    margin: 0;
                    span {
                        font-weight: normal;
                        font-size: 9px;
                    }
                }
                .element-input  {
                    background: #F3F3F3;
                    border-radius: 9px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    .StripeElement  {
                        width: 100%;
                        padding-left: 13px;
                        padding-right: 13px;
                        font-size: 16px;
                        color: var(--rc-black);
                        margin-top: 2px;
                        .InputElement  {
                            font-size: 14px !important;
                            &::placeholder {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .note {
                font-size: 9px;
                color: var(--rc-black);
                margin: 4px 10px 0;
            }
        }
    }
    .box-btn-group {
        // padding: 0 6px 36px;
        padding-top: 30px;
        border-radius: 0;
        display: flex;
        margin-top: 0;
        .ant-btn {
            width: 50%;
            border-radius: 4px;
            height: 50px;
            background-color: var(--rc-header);
            color: var(--rc-white);
            font-size: 14px;
            font-weight: 900;
            &:last-child {
                span {
                    font-weight: 700;
                    font-size: 15px;
                }
                margin-inline-start: 8px;
            }
            &.ant-btn-primary {
                background-color: var(--rc-green);
            }
        }
    }
}

.box-btn-group-charge-point {
    // padding: 0 6px 36px;
    padding-top: 9px;
    border-radius: 0;
    display: flex;
    margin-top: 0;
    .ant-btn {
        font-weight: 900;
        width: 154px;
        border-radius: 4px;
        height: 50px;
        background-color: var(--rc-header);
        color: var(--rc-white);
        &:last-child {
            margin-inline-start: 8px;
        }
        &.ant-btn-primary {
            background-color: var(--rc-green);
            &.disable {
                background-color: var(--rc-gray-v5);
            }
        }
    }
}
