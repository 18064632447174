.room-content {
    padding: 9px 0px 0px 26px;
    &:last-child {
        padding-top: 0;
    }
    .address {
        display: flex;
        font-size: 13px;
        margin: 0 0 19px;
        color: var(--rc-main);
        font-weight: 400;
        padding-right: 20px;

        span {
            &:nth-child(2) {
                white-space: pre-line;
            }
        }
    }
    .room-list {
        font-size: 9px;
        margin-bottom: 18px;
        .room-item {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            .room-item__title {
                width: 68px;
                height: 20px;
                background: var(--rc-gray-v2);
                color: var(--rc-white);
                @include center_flex;
                line-height: 1;
                text-transform: uppercase;
                font-weight: 900;
            }
            .room-item__desc {
                font-weight: 400;
                font-size: 11px;
                width: calc_v2(68px);
                padding-left: 12px;
                color: var(--rc-gray);
            }
        }
    }
    .ant-btn {
        font-size: 13px;
        color: var(--rc-main);
        border-bottom: 1px solid rgb(0, 0, 0, 0.2);
        border-radius: 0;
        width: inherit;
        padding: 0;
        height: inherit;
        font-family: 'Noto Sans JP', sans-serif;

        &:hover{
            color: var(--rc-main);
        }
    }
    .btn-add-card {
        font-size: 13px;
        color: var(--rc-main);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
        width: inherit;
        padding: 0;
        height: inherit;
        &.lost-link-btn {
            color: var(--rc-red);
            border-bottom: 1px solid var(--rc-red);
        }
    }
}


.box-btn {
    .ant-space {
        display: flex;
        margin: auto;
        width: calc(100vw - 50px);
        gap: 15px !important;

        .btn-re-entry {
            font-size: 15px;
            height: 50px;
            background-color: var(--rc-orange);
        }
    }
}

.room-step-two {
    padding: 0 26px 34px;
    .ant-divider {
        width: 102%;
        border-bottom: 1px solid var(--rc-header);
        margin: 0;
    }
    .box-group {
        line-height: 1;
        text-align: right;
        padding: 8px 0 20px;
        .desc {
            font-weight: 500;
            color: var(--rc-red);
            font-size: 11px;
            margin: -3px 0px 2px 0px;
        }
        .confirm-price {
            margin: 0;
            color: var(--rc-red);
            font-size: 11px;
            font-weight: 500;
            margin: 4px 0 15px 0;
        }
        .stone {
            font-weight: 700;
            color: var(--rc-black);
            letter-spacing: 0.09em;
            font-size: 24px;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            margin: 5px 0 3px 0;
            p {
                font-weight: 700;
                margin: 0;
                font-family: 'Century' !important;
                font-size: 26px;
                &:first-child {
                        letter-spacing: -0.5px;
                }
                &:last-child {
                    padding-bottom: 5px;
                    padding-left: 4px;
                    font-size: 16px;
                    span {
                        font-weight: 700;
                    }
                }

                .point-time {
                    letter-spacing: -0.5px;
                    font-size: 14px;
                    font-weight: 700;
                    span {
                        letter-spacing: -0.5px;
                        font-size: 16px;
                    }
                }
            }
        }
        .stone-real {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            font-weight: 500;
            p {
                margin: 0;
                color: var(--rc-main);
                font-size: 11px;
                font-weight: 500;
                &:first-child {
                    font-size: 12px;
                }
                &:nth-child(2) {
                    letter-spacing: -0.5px;
                    display: inline-block;
                    margin-right: 1px;
                    font-weight: 400;
                    font-size: 14px;
                    padding-left: 8px;
                    font-family: "Century" !important;
                }
            }
        }
        .btn-custom {
            display: inline-block;
            p {
                padding-bottom: 3px;
                font-weight: 500;
                font-size: 13px;
                color: var(--rc-main);
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 0;
                margin: 9px 0 9px 0;
            }
        }
        .ant-btn {
            border: 1px solid var(--rc-green);
            height: 26px;
            @include center_flex;
            color: var(--rc-green);
            font-size: 11px;
            letter-spacing: 0.09em;
            font-weight: 900;
            border-radius: 100px;
            margin-left: auto;
        }
    }
    .box-btn-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-custom {
            line-height: 1;
            &:nth-child(2) {
                width: calc(100vw - 52px);
                margin-bottom: 12px;
                .ant-btn {
                    width: 100%;
                    font-size: 15px;
                    font-weight: 900;
                    background-color: var(--rc-green);
                    color: var(--rc-white);
                    height: 50px;
                    &:disabled {
                        background-color: var(--rc-header);
                        opacity: 1;
                        color: var(--rc-white);
                    }
                    &.danger {
                        background-color: var(--rc-red-v2);
                    }
                }
            }
            &:nth-child(1) {
                margin-bottom: 10px;
                font-weight: 500;
                border-bottom: 1px solid var(--rc-red);
                border-radius: 0;
                width: inherit;
                padding: 0;
                height: inherit;
                font-family: 'Noto Sans JP', sans-serif;
                p {
                    margin: 0;
                    color: var(--rc-red-v4);
                    font-size: 10px;
                }
            }
        }
    }
}

.room-step-three {
    padding: 11px;
    .box-title {
        line-height: 1;
        text-align: center;
        margin-bottom: 25px;
        .title {
            margin: 5px 0px 9px 0px;
            color: var(--rc-gray-v2);
            font-weight: 900;
            font-size: 24px;
        }
        .desc {
            font-size: 13px;
            color: var(--rc-main);
            font-weight: 500;
            margin: 0;
        }
    }
    .group-qr {
        &.re-entry {
            margin-top: 48px;
        }

        text-align: center;
        color: var(--rc-red);
        font-weight: 900;
        .keep-qr {
            font-size: 14px;
            margin: 0 0 15px;
        }
        .expired {
            font-weight: 700;
            font-size: 16px;
            margin: 0 0 25px;
        }
    }
    .qr-code {
        margin: 34px 0;
        .ant-qrcode {
            margin: 0 auto;
            background: none;
            border: none;
            // padding: 0;
        }
        .ant-skeleton {
            margin: 0 auto;
            width: 100%;
        }
    }
    .note {
        @include center_flex;
        justify-content: flex-start;
        // height: 144px;
        padding: 10px 20px;
        color: var(--rc-red);
        border-radius: 6px;
        font-size: 10px;
        font-weight: 900;
        line-height: 214.3%;
        white-space: pre-line;
        text-decoration: underline;
    }
}

.shop-entry {
    padding: 11px;
    .qr-code {
        margin: 11px 0 8px 0;
        text-align: center;
        .btn-entry {
            padding: 0;
            height: unset;
            border: unset;
            margin: 0 45px 15px 45px;
            color: var(--rc-red);
            font-weight: 900;
            font-size: 12px;
            border-bottom: 1px solid var(--rc-red);
            border-radius: 0px;
            background-color: unset;
            box-shadow: unset;
        }
        .ant-skeleton {
            margin-bottom: 16px;
        }
    }
    .point {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 30px;
        .stone {
            font-weight: 700;
            color: var(--rc-black);
            letter-spacing: 0.09em;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin: 5px 0 5px 0;
            p {
                margin: 0;
                font-family: 'Century' !important;
                font-size: 26px;
                &:first-child {
                    letter-spacing: -0.5px;
                }
                &:last-child {
                    padding-bottom: 5px;
                    padding-left: 4px;
                    font-size: 16px;
                    span {
                        font-weight: 700;
                    }
                }
                .point-time {
                    letter-spacing: -0.5px;
                    font-size: 14px;
                    font-weight: 700;
                    span {
                        letter-spacing: -0.5px;
                        font-size: 16px;
                    }
                }
            }
        }
        .stone-real {
            display: flex;
            justify-content: center;
            align-items: baseline;
            font-weight: 500;
            p {
                letter-spacing: -0.5px;
                margin: 0;
                color: var(--rc-gray-v2);
                font-size: 12px;
                &:first-child {
                    font-size: 12px;
                }
                &:nth-child(2) {
                    font-weight: 400;
                    font-size: 12px;
                    padding-left: 8px;
                    font-family: "Century" !important;
                }
            }
        }
        .ant-skeleton-content {
            padding-top: 16px;
            .ant-skeleton-title {
                margin: 0 auto;
            }
            .ant-skeleton-paragraph {
                li {
                    margin: 0 auto;
                }
            }
        }
    }
}

.lost-item {
    .ant-skeleton-title {
        margin: 0 auto;
    }
    @extend .room-step-three;
    // .group-qr {
    //     padding-top: 32px;
    // }
    .desc {
        color: var(--rc-red);
        text-align: center;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
    }
    .qr-code {
        margin: 11px 0 8px;
    }
    .note {
        border: unset;
        padding: 0;
        border-radius: unset;
        position: fixed;
        bottom: 114px;
        left: 0;
        width: 100%;
        justify-content: center;
        line-height: 1.5;
        span {
            font-weight: 400;
            font-size: 11px;
        }
    }
}

.profile-qr {
    padding: 11px 27px;
    .desc {
        margin: 0;
        font-size: 12px;
        color: var(--rc-red);
        font-weight: 700;
    }
    .qr-code {
        margin: 34px 0;
        .ant-qrcode {
            margin: 0 auto;
            background: none;
            border: none;
            // padding: 0;
        }
    }
    table {
        margin-top: 8px;
        color: var(--rc-gray-v2);
        tbody {
            line-height: 1;
        }
        tr {
            display: inline-block;
            margin-bottom: 10px;
            &.mb-27 {
                margin-bottom: 27px;
            }
            &:last-child {
                td {
                    &:nth-child(2) {
                        padding-top: 2px;
                        font-size: 34px;
                    }
                }
            }
            &:nth-child(3) {
                td {
                    &:nth-child(3) {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }
            td {
                &:first-child {
                    padding-top: 8px;
                    font-weight: 900;
                    font-size: 18px;
                    color: var(--rc-gray-v2);
                    text-align: center;
                    padding-right: 13px;
                    line-height: 23,17px;
                }
                &:nth-child(2) {
                    font-weight: 700;
                    font-family: 'Century' !important;
                    width: 100px;
                    text-align: right;
                    font-size: 36px;
                }
                &:nth-child(3) {
                    font-size: 18px;
                    font-weight: 900;
                    text-align: left;
                    padding-top: 12px;
                }
            }
        }
    }

    .note_exit {
        color: var(--rc-red);
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        position: fixed;
        bottom: 103px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .ant-btn {
        font-size: 11px;
        color: var(--rc-main);
        border-bottom: 1px solid var(--rc-red);
        border-radius: 0;
        width: inherit;
        padding: 0;
        height: inherit;
    }
}

.qr-code-success {
    @extend .profile-qr;
    .group-qr {
        text-align: center;
        color: var(--rc-red);
        font-weight: 900;
        margin-bottom: 10px;
        .keep-qr {
            font-size: 12px;
            margin: 0 0 4px;
        }
        .expired {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
        }
    }
    .btn-exit {
        height: unset;
        padding: 0;
        background-color: unset;
        border: unset;
        text-align: center;
        margin: 0 0px 27px 0px;
        color: var(--rc-red);
        font-weight: 900;
        font-size: 12px;
        border-bottom: 1px solid var(--rc-red);
    }
    .qr-code {
        text-align: center;
        margin: 11px 0 8px;
        .ant-qrcode {
            margin: 8px auto;
            background: none;
            border: none;
        }
        .ant-skeleton {
            margin: 0 auto;
            width: 100%;
        }
    }
    .list {
        margin-bottom: 40px;
        .item {
            .item-col {
                color: var(--rc-gray-v2);
            }
        }
    }
    .btn-custom {
        text-align: center;
        .ant-btn {
            color: var(--rc-red);
            font-weight: 500;
        }
        .re-entry {
            color: var(--rc-gray-v6);
            border-bottom-color: var(--rc-gray-v6);
        }
    }
    .btn-qr-code {
        position: fixed;
        bottom: 135px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 50px);
        &.no_fix {
            padding-top: 60px;
        }
        .ant-btn {
            width: 100%;
            font-size: 15px;
            font-weight: 900;
            background-color: var(--rc-green);
            color: var(--rc-white);
            height: 50px;
            border-radius: 100px;
            border: 1px solid var(--rc-green);
            &:disabled {
                cursor: not-allowed;
                border-color: #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
                background-color: rgba(0, 0, 0, 0.04);
                box-shadow: none;
            }
        }
        .btn-re-entry {
            width: calc(100vw - 50px);
            height: 50px;
            background-color: var(--rc-orange) !important;
            border: unset;
        }
    }

    .note {
        color: var(--rc-red);
        font-size: 11px;
        position: fixed;
        bottom: 103px;
        left: 0;
        width: 100%;
        text-align: center;
        line-height: 1.5;
        font-weight: 400;
        span {
            border-bottom: 1.5px solid var(--rc-red);
        }
        &.no_fix {
            position: unset;
        }
    }
}

.height_no_fix {
    position: unset !important;
    width: 100% !important;
    transform: unset !important;
    padding-top: 20px;
}

.exit-skeleton {
    padding: 56px 41px 40px 41px;
    .desc {
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-title {
                    width: 100% !important;
                }
            }
        }
    }
    .qr-code {
        text-align: center;
        margin: 0px 0px 34px 0;
    }
    .list {
        .item {
            .ant-skeleton {
                .ant-skeleton-content {
                    .ant-skeleton-title {
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .btn-custom {
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-title {
                    width: 100% !important;
                }
            }
        }
    }
}
