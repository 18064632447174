.custom-modal {
    font-weight: 900;
    &.user-point {
        .mb-13 {
            margin-bottom: 13px;
        }
        font-weight: inherit;
        .ant-modal {
            color: var(--rc-gray-v2);
            .ant-modal-content {
                .ant-modal-body {
                    border: 1px solid var(--rc-header);
                    padding: 35px 13px 28px 28px;
                }
            }
            .ant-modal-close {
                .ant-modal-close-x {
                    svg {
                        height: 24px;
                        width: 20px;
                    }
                }
                top: 3px;
                inset-inline-end: 10px;
            }
        }
    }
    .ant-modal {
        max-width: calc(100vw - 50px);
        .ant-modal-content {
            padding: 0;
            border-radius: 0;
            .ant-modal-header {
                height: 70px;
                margin: 0;
                background-color: var(--rc-orange);
                border-radius: 0;
                @include center_flex;
                .ant-modal-title {
                    font-size: 24px;
                    color: var(--rc-white);
                    font-weight: 900;
                }
            }
            .font-number {
                font-family: 'Century' !important;
            }
            .ant-modal-footer {
                padding: 0 6px 20px;
                border-radius: 0;
                display: flex;
                margin-top: 0;
                .ant-btn {
                    font-weight: 900;
                    width: 50%;
                    border-radius: 4px;
                    height: 50px;
                    background-color: var(--rc-header);
                    color: var(--rc-white);
                    &.ant-btn-primary {
                        background-color: var(--rc-orange);
                    }
                }
            }
        }
    }
    &.orange {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-body {
                    padding: 40px 26px;
                }
                .ant-modal-header {
                    background-color: var(--rc-orange);
                }
                .ant-modal-footer {
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-orange);
                        }
                    }
                }
            }
        }
    }
    &.confirm-etiquette {
        .ant-modal {
            .ant-modal-content {
                min-height: 372px;
                .ant-modal-body {
                    padding: 35px 14px 0px 15px;
                }
                .ant-modal-header {
                    background-color: var(--rc-orange);
                }
                .ant-modal-footer {
                    margin-top: 24px;
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-orange);
                        }
                    }
                }
            }
        }
    }
    &.green {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-body {
                    padding: 45px 12px 41px 13px;
                }
                .ant-modal-header {
                    background-color: var(--rc-green);
                }
                .ant-modal-footer {
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-green);
                        }
                    }
                }
            }
        }
    }
    &.red {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-header {
                    background-color: var(--rc-red);
                }
                .ant-modal-footer {
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-red);
                        }
                    }
                }
            }
        }
    }
    &.black {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-header {
                    background-color: var(--rc-black);
                }
                .ant-modal-footer {
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-green);
                        }
                    }
                }
            }
        }
    }
    &.custom-charge-point {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-body {
                    padding: 29px 12px 1px 12px;
                }
                .ant-modal-footer {
                    padding: 0 12px 36px;
                }
            }
        }
    }
    &.policy {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-body {
                    padding: 29px 12px 16px 12px;
                }
                .ant-modal-footer {
                    padding: 0 12px 36px;
                }
            }
        }
    }
    &.add-card {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-header {
                    .ant-modal-title {
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
                .ant-modal-body {
                    padding: 23px 12px 46px 12px;
                }
                .ant-modal-footer {
                    padding: 0 12px 36px;
                }
            }
        }
    }
    &.finish {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-body {
                    padding: 0 18px;
                }
                .ant-modal-header {
                    background-color: var(--rc-orange);
                    .ant-modal-title {
                        font-size: 32px;
                        font-weight: 700;
                    }
                }
                .ant-modal-footer {
                    .ant-btn {
                        &.ant-btn-primary {
                            background-color: var(--rc-orange);
                        }
                    }
                }
            }
        }
    }
    &.not-enough-point {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-header {
                    .ant-modal-title {
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    &.confirm-charge-point {
        .ant-modal {
            .ant-modal-header {
                .ant-modal-title {
                    color: var(--rc-white-v2);
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            .ant-modal-content {
                .ant-modal-body {
                    padding: 40px 12px 34px 12px;
                    .text-center {
                        color: var(--rc-gray-v2);
                        text-align: center;
                        
                        .text-top {
                            vertical-align: text-bottom;
                        }
                    }
                    
                    .form-stripe {
                        .box-stripe {
                            padding: 0;
                        }

                        .box-btn-group {
                            padding-top: 37px;
                        }
                    }
                }
                .ant-modal-footer {
                    padding: 0 12px 25px;
                    .ant-btn {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &.update-profile {
        .ant-modal {
            .ant-modal-content {
                .ant-modal-header {
                    .ant-modal-title {
                        font-size: 22px;
                    }
                    background-color: var(--rc-header);
                }
                .ant-modal-body {
                    padding: 24px;
                }
                .ant-form-item {
                    margin-bottom: 0;
                    .ant-input {
                        border-radius: 2px;
                    }
                }
                .ant-form-item-explain-error {
                    font-size: 12px;
                    font-weight: 500;
                    position: absolute;
                    top: calc(100% - 3px);
                }
            }
        }
    }
    &.modal-list-mail {
        .ant-modal {
            max-width: calc(100% - 116px);
            .ant-modal-content {
                border-radius: 10px;
                box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.25);
                .ant-btn {
                    border-radius: unset;
                }
                .mail-item {
                    &:first-child {
                        .ant-btn {
                            border-radius: 10px;
                        }
                    }
                    a {
                        height: 46px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-top: 1px solid var(--rc-gray-v8);
                        padding-left: 39px;
                        padding-right: 30px;
                        span {
                            color: var(--rc-gray-v2);
                        }
                    }
                }
            }
            }
    }
}

.comfirm-1 {
    .title {
        font-size: 16px;
        color: var(--rc-main);
        font-weight: 900;
        line-height: 23px;
        margin: 0;
        white-space: pre-line;
    }
    .confirm-group-check {
        padding: 38px 12px 55px;
        .confirm-item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            .confirm-item__img {
                width: 18px;
                height: 20px;
                img {
                    width: 100%;
                }
            }
            .confirm-item__text {
                width: calc(100% - 18px);
                padding-left: 8px;
                color: var(--rc-gray-v2);
                font-size: 13px;
                font-weight: 900;
            }
        }
    }
    .confirm-desc {
        color: var(--rc-gray-v2);
        font-size: 10px;
        font-weight: 900;
        margin: 0;
        line-height: 14px;
    }
}

.comfirm-2 {
    .title {
        font-size: 13px;
        color: var(--rc-main);
        font-weight: 900;
        line-height: 23px;
        margin: 0;
        white-space: pre-line;
    }
    .confirm-radio {
        font-size: 13px;
        color: var(--rc-gray-v2);
        padding: 43px 15px 0px;
        .ant-radio-wrapper {
            .ant-radio-checked {
                .ant-radio-inner {
                    background: var(--rc-green);
                    border-color: var(--rc-green);
                }
            }
        }
        .ant-checkbox-wrapper {
            &:last-child {
                font-weight: 900;
                font-size: 13px;
                color: var(--rc-gray-v2);
            }
            .ant-checkbox {
                &.ant-checkbox-checked {
                    &::after {
                        border-radius: 50%;
                        border: 1px solid var(--rc-green);
                    }
                    .ant-checkbox-inner {
                        background-color: var(--rc-green);
                    }
                }
                .ant-checkbox-inner {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 1px solid var(--rc-green);
                }
            }
        }
    }
}

.modal-confirm-3 {
    .ant-modal {
        .ant-modal-content {
            .ant-modal-body {
                padding: 0;
            }
        }
    }
}
.confirm-3 {
    height: 318px;
    // background-color: red;
    position: relative;
    .title {
        margin: 0;
        position: absolute;
        top: 40px;
        width: 100%;
        color: var(--rc-main);
        line-height: 23px;
        font-size: 16px;
        z-index: 9;
        font-weight: 900;
    }
    .time {
        @include center_pos;
        top: 61%;
        color: var(--rc-orange);
        font-weight: 700;
        display: flex;
        align-items: flex-start;
        z-index: 9;
        .num {
            font-weight: 700;
            font-family: 'Century' !important;
            font-size: 96px;
            line-height: 1;
        }
        .txt {
            font-size: 16px;
            white-space: pre-line;
            font-weight: 900;
            padding: 17px 0;
            line-height: 23px;
            width: 50px;
        }
    }
    .bg-img-left {
        content: "";
        position: absolute;
        top: 28px;
        left: -14px;
        img {
            width: 140px;
        }
    }
    .bg-img-right {
        content: "";
        position: absolute;
        bottom: -4px;
        right: -15px;
        img {
            width: 120px;
        }
    }
}

.confirm-4 {
    padding: 36px 66px 0 65px;
    height: 183px;
    text-align: center;
    .title {
        color: var(--rc-black);
        font-size: 15px;
        font-weight: 700;
        white-space: pre;
        margin-bottom: 35px;
        line-height: 20px;
    }
    .ant-btn {
        border-color: var(--rc-green);
        color: var(--rc-green);
        font-size: 16px;
        font-weight: 900;
        height: 42px;
        letter-spacing: 0.09em;
        width: 209px;
        &:not(:disabled):hover {
            border-color: var(--rc-green);
            color: var(--rc-green);
        }
    }
}

.custom-modal-button {
    .ant-modal {
        .ant-modal-content {
            background: unset;
            box-shadow: unset;
            .ant-modal-body {
                text-align: center;
                .ant-btn {
                    background: rgba(60, 171, 36, 0.9);
                    color: var(--rc-white);
                    font-weight: 700;
                    font-size: 24px;
                    height: 68px;
                    width: 100%;
                    border: none;
                }
            }
        }
    }
}
